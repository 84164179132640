import './jquery-global.js';
import '../node_modules/jquery-validation/dist/jquery.validate.min.js';
import '../node_modules/jquery-validation/dist/localization/messages_fr.js';
import Blazy from "blazy";
import Slick from "slick-carousel";
import { Fancybox } from "@fancyapps/ui";

$(function() {
  const header = $('.header');
  const body = $('body');
  const hamburger = $('.hamburger');
  const navigation = $('.navigation--nav');
  const btnTop = $('.footer--to-top', '.footer');

  // Is Mobile ?
  function checkMobile() {
    if (hamburger.css('display') === 'none') {
      hamburger.removeClass('is-active');
      body.removeClass('is-fixed');
      navigation.removeAttr('style');
    }
  }
  checkMobile(); // Au chargement de la page
  $(window).resize(checkMobile); // Au redimensionnement de la page

  // Bouton Hamburger
  $(hamburger, header).click((e) => {
    e.preventDefault();
    hamburger.toggleClass('is-active');
    body.toggleClass('is-fixed');
    header.toggleClass('is-open-mobile');
    $('.navigation--list .link').removeClass('is--clicked');
  });

   // Bouton Menu Mobile
  $("html").on('click' ,'.is-open-mobile .navigation--list .link', function(e) {
    $('.is-open-mobile .navigation--list .link').not(this).removeClass('is--clicked');
    $(this).toggleClass('is--clicked');
  });

  // Header - Scrolled
  $(window).on("scroll", function() {
    if($(window).scrollTop() > 0) {
      header.addClass("is--scrolled");
      btnTop.addClass('is--scrolled');
    } else {
      header.removeClass("is--scrolled");
      btnTop.removeClass('is--scrolled');
    }
  });

  // Header - Scrolled (au chargement)
  if ($(this).scrollTop() >= 1) {
    header.addClass('is--scrolled');
    btnTop.addClass('is--scrolled');
  } else {
    header.removeClass('is--scrolled');
    btnTop.removeClass('is--scrolled');
  }

  // Lazyload (Media) knkn
  const bLazy = new Blazy({
    offset: 10,
    success() {
    },
  });

  // Bouton - Haut de page
  btnTop.click((e) => {
    e.preventDefault();
    $('html, body').animate({ scrollTop: 0 }, 300);
  });

  // Slider - Product
  $('.home--slider').slick({
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true,
    arrows: false
  });

  // // Slider - Product
  // $('.product--images-featured').slick({
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   arrows: false,
  //   fade: true,
  //   asNavFor: '.product--images-thumbs',
  //   rows: 0,
  // });

  // $('.product--images-thumbs').slick({
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   asNavFor: '.product--images-featured',
  //   focusOnSelect: true,
  //   arrows: false,
  //   centerMode: true,
  //   rows: 0,
  // });

  // Fix bug slider with key arrow
  // const $carousel = $('.product--images-thumbs');
  // $(document).on('keydown', (e) => {
  //   if (e.keyCode === 37) {
  //     $carousel.slick('slickPrev');
  //   }
  //   if (e.keyCode === 39) {
  //     $carousel.slick('slickNext');
  //   }
  // });

  $('.home--slider').on('beforeChange', () => {
    bLazy.revalidate();
  });

  // $('.product--tabs li').click((e) => {
  //   const tabId = `#${$(e.currentTarget).attr('data-tab')}`;
  //   $('.product--tab__link').removeClass('is-active');
  //   $('.product--tab__content').removeClass('is-active');
  //   $(e.currentTarget).addClass('is-active');
  //   $(tabId).addClass('is-active');
  // });

  // Formulaire de contact
  if ($('window')) {
    $('.contact-form').validate();
  }
  // Obfuscation de lien (PR Sculpting)
  $('[data-obf]').each(function() {
    $(this).click(function(event) {
      let attribute = $(this).data('obf');
      let action = $(this).data('action');

      if (event.shiftKey || event.ctrlKey || event.metaKey || action === 'blank') {
        let newWindow = window.open(decodeURIComponent(window.atob(attribute)), '_blank');
        newWindow.focus();
      } else {
        document.location.href= decodeURIComponent(window.atob(attribute));
      }
    });
  });

  $(document).on("mousedown", "[data-obf]", function(event) {
    if( event.which == 2 ) {
      let attribute = $(this).data('obf');
      let newWindow = window.open(decodeURIComponent(window.atob(attribute)), '_blank');
      newWindow.focus();
    }
  });

  // Fit height textarea to content - Contact
  $('textarea').each((index, el) => {
    const $this = $(el);
    const minHeight = $this.height();

    const shadow = $('<div></div>').css({
      position: 'absolute',
      top: -10000,
      left: -10000,
      width: $this.width() - parseInt($this.css('paddingLeft'), 10) - parseInt($this.css('paddingRight'), 10),
      fontSize: $this.css('fontSize'),
      fontFamily: $this.css('fontFamily'),
      lineHeight: $this.css('lineHeight'),
      resize: 'none',
    }).appendTo(document.body);

    const update = () => {
      const times = (string, number) => {
        let res = '';
        for (let i = 0; i < number; i += 1) {
          res += string;
        }
        return res;
      };

      const val = $this.val().replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/&/g, '&amp;')
        .replace(/\n$/, '<br/>&nbsp;')
        .replace(/\n/g, '<br/>')
        .replace(/ {2,}/g, (space) => `${times('&nbsp;', space.length - 1)} `);

      shadow.html(val);
      $this.css('height', Math.max(shadow.height() + 20, minHeight));
    };

    $this.css('overflow', 'hidden')
      .css('resize', 'none')
      .change(update)
      .keyup(update)
      .keydown(update);
    update.apply(this);
  });
});
